.container {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 12px;
    border-radius: 8px;
    border: 2px solid var(--color-border);
}
.name {
    font-size: 1.25rem;
    font-weight: 600;
}
.company {
    display: flex;
    gap: 1em;
    color: var(--color-2);
    align-items: center;
}
.date {
    font-size: 0.9rem;
    color: var(--color-3);
}
.stackList {
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
}
.tech {
    padding: 4px 12px;
    background: var(--bg-2);
    font-size: 0.9rem;
    color: #8e8ee8;
    border-radius: 6px;
}
.responsibilities {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}
.responsibilitiesList {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin-left: 0.5em;
}
.responsibility {
    color: var(--color-2);
}
.description {
    margin: 0.5em 0;
}
