.container {
    display: flex;
    border: 1px solid var(--color-border);
    padding: 6px 12px;
    border-radius: 6px;
    flex-direction: column;
    width: 150px;
}
.top {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    font-size: 0.75rem;
    color: var(--color-3);
}
.name {
    font-size: 1.25rem;
}
.lang {
    color: #8e8ee8;
}