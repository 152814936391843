.container {
    display: flex;
    flex-direction: column;
    margin: 1em;
    gap: 0.5em;
}
.headline {
    font-size: 1.25rem;
}
.list {
    display: flex;
    gap: 1em;
}
.link {
    text-decoration: none;
    color: var(--color-3);
    border: 2px solid var(--color-border);
    padding: 6px 12px;
    border-radius: 6px;
    transition-duration: 0.2s;
    animation: appear2 0.5s forwards;
}
.link:hover {
    border-color: var(--color-1);
    color: var(--color-1);
}
.right {
    margin-left: auto;
}

@keyframes appear2 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}