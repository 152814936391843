@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

:root {
    --bg: #1b1b1d;
    --bg-2: #28282b;

    --color-1: #f0f0f4;
    --color-2: #c2c2c6;
    --color-3: #959598;

    --color-border: rgba(255, 255, 255, 0.1);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--bg);
    color: var(--color-1);
    display: flex;
    min-height: 100vh;
    min-height: 100dvh;
    font-family: "Rubik", sans-serif;
}
#root {
    display: flex;
    flex: 1;
    flex-direction: column;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
