.container {
    display: flex;
    border: 2px solid var(--color-border);
    text-decoration: none;
    color: var(--color-1);
    gap: 0.25em;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    border-radius: 8px;
    transition-duration: 0.2s;
}
.container:hover {
    border-color: var(--color-1);
}