.container {
    align-self: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    animation: appear 0.5s forwards;
    width: 600px;
}
.fadeOut {
    animation: fadeOut 0.5s forwards;
}
.headline {
    font-size: 3rem;
    margin-bottom: 8px;
    text-decoration: underline 2px solid var(--color-3);
    text-underline-offset: 18px;
    white-space: nowrap;
}
.fade {
    color: var(--color-3);
    font-size: 1.5rem;
}
.description {
    margin: 8px 0;
    font-size: 1.25rem;
    color: var(--color-2);
    line-height: 1.75rem;
}
.attachments {
    display: flex;
    flex-direction: row;
    gap: 1em;
    flex-wrap: wrap;
}
@keyframes appear {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(-100px);
    }
}